




























































import {Vue, Component, Prop} from 'vue-property-decorator';
import CusTable from "@/components/table";
import MixinEdu from "@/views/education/Mixins/mixin-edu";

@Component({
    mixins: [MixinEdu]
})
export default class extends Vue {
    private data = []
    private AddDialogVisible = false
    private inputVal = ''
    private rowId = ''
    $refs!: { table: CusTable }

    private async addQuesSource() {
        const res: any = await this.axios.post('/system/dictionary/saveOrUpdate', {
            id: this.rowId,
            typeCode: (this.$route as any).meta.type,
            name: this.inputVal
        });
        if (res.result) this.AddDialogVisible = !this.AddDialogVisible, this.$refs.table.request();
    }

    private selectionChange(ev) {
        this.selectedArr = ev;
    };

    private handleEdit(index, row) {
        this.AddDialogVisible = true;
        this.rowId = row.id;
        this.inputVal = row.name;
    }

    /**
     * 停用 && 启用
     */
    private handleChangeStatus(idx, row: never | any) {
        this.selectedArr = [row];
        this.batchChangeStatus((row.status == 1 ? 0 : 1), this.$refs.table)
    }

    handleDelete(idx, row) {
        this.selectedArr = [row];
        this.batchDeleteHandle(this.$refs.table);
    }

    private batchChangeStatusHandle(status) {
        this.batchChangeStatus(status, this.$refs.table);
    };
}
